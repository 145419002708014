const menus = [
  {
    id: 1,
    name: "Home",
    links: "/",
  },
  {
    id: 2,
    name: "About",
    links: "/",
    namesub: [
      {
        id: 1,
        sub: "How It Works",
        links: "/howitworks",
      },
      {
        id: 2,
        sub: "Benefits",
        links: "/benefits",
      },
    ],
  },
  // {
  //   id: 3,
  //   name: "Projects",
  //   links: "/projects",
  //   // namesub: [
  //   //   {
  //   //     id: 1,
  //   //     sub: "Vendor Dashboard",
  //   //     links: "/dashboard",
  //   //   },
  //   //   {
  //   //     id: 2,
  //   //     sub: "Vendor User",
  //   //     links: "/dashboard",
  //   //   },
  //   //   {
  //   //     id: 3,
  //   //     sub: "Vendor Specific",
  //   //     links: "/collection",
  //   //   },
  //   // ],
  // },
  {
    id: 3,
    name: "NGO",
    links: "/ngo-projects",
  },
  {
    id: 4,
    name: "PROFITABLE",
    links: "/profitable-projects",
  },
  // {
  //   id: 5,
  //   name: "Subscription",
  //   links: "/",
  // },
  {
    id: 6,
    name: "Knowledge",
    links: "/knowledge",
    namesub: [
      {
        id: 1,
        sub: "Knowledge",
        links: "/knowledge",
      },
      {
        id: 2,
        sub: "Blog",
        links: "/blog-v2",
      },
    ],
  },
  {
    id: 7,
    name: "Commerce",
    links: "/commerce",
  },
];

export default menus;

export const sitemenu = [
  {
    id: 1,
    name: "Home",
    links: "#",
    namesub: [
      {
        id: 1,
        sub: "Home 01",
        links: "/",
      },
      {
        id: 2,
        sub: "Home 02",
        links: "/home-v2",
      },
      {
        id: 3,
        sub: "Home 03",
        links: "/home-v3",
      },
      {
        id: 4,
        sub: "Home 04",
        links: "/home-v4",
      },
      {
        id: 5,
        sub: "Home 05",
        links: "/home-v5",
      },
      {
        id: 6,
        sub: "Home 06",
        links: "/home-v6",
      },
    ],
  },
  {
    id: 2,
    name: "Explore",
    links: "#",
    namesub: [
      {
        id: 1,
        sub: "Explore v1",
        links: "/explore-v1",
      },
      {
        id: 2,
        sub: "Explore v2",
        links: "/explore-v2",
      },
      {
        id: 3,
        sub: "Explore v3",
        links: "/explore-v3",
      },
      {
        id: 4,
        sub: "Explore v4",
        links: "/explore-v4",
      },
      {
        id: 5,
        sub: "Collection",
        links: "/collection",
      },
      {
        id: 6,
        sub: "Live Auction v1",
        links: "/live-auctions-v1",
      },
      {
        id: 7,
        sub: "Live Auction v2",
        links: "/live-auctions-v2",
      },
      {
        id: 8,
        sub: "Item Details v1",
        links: "/item-details-v1",
      },
      {
        id: 9,
        sub: "Item Details v2",
        links: "/item-details-v2",
      },
    ],
  },
  {
    id: 4,
    name: "Pages",
    links: "#",
    namesub: [
      {
        id: 1,
        sub: "Dashboard ",
        links: "/dashboard ",
      },
      {
        id: 2,
        sub: "Ranking",
        links: "/ranking",
      },
      {
        id: 3,
        sub: "Help Center",
        links: "/help-center",
      },
      {
        id: 4,
        sub: "FAQ",
        links: "/faqs",
      },
      {
        id: 5,
        sub: "Connect Wallet",
        links: "/wallet",
      },
      {
        id: 6,
        sub: "Login",
        links: "/login",
      },
      {
        id: 7,
        sub: "Sign Up",
        links: "/signup",
      },
    ],
  },
  {
    id: 5,
    name: "Create",
    links: "/create",
  },
  {
    id: 6,
    name: "Blog",
    links: "#",

    namesub: [
      {
        id: 1,
        sub: "Blog v1",
        links: "/blog-v1",
      },
      {
        id: 2,
        sub: "Blog v2",
        links: "/blog-v2",
      },
      {
        id: 3,
        sub: "Blog Details v1",
        links: "/blog-details-v1",
      },
      {
        id: 4,
        sub: "Blog Details v2",
        links: "/blog-details-v2",
      },
    ],
  },

  {
    id: 7,
    name: "Contact",
    links: "/contact",
  },
];
