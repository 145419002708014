import { createContext, useContext, useState,useEffect } from "react"

const ThemeContext = createContext();

export default function AppContext({ children }) {
  const [isAuthenticated, setisAuthenticated] = useState(false)

  useEffect(() => {
    let uinfo = JSON.parse(localStorage.getItem("antsInformation")) || []

    if (uinfo.length !== 0) {
      setisAuthenticated(true)
    } else {
      setisAuthenticated(false)
    }
  }, [])
  return <ThemeContext.Provider value={{
    isAuthenticated, 
    setisAuthenticated,
  }}>
    {children}
  </ThemeContext.Provider>
}


export const useStateContext = () => useContext(ThemeContext);